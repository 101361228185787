import {
  formatDistanceStrict,
  differenceInDays,
  formatDistance,
} from "date-fns";
import { fromZonedTime } from "date-fns-tz";

export const newUTCDate = (dateTime: string | number | Date): Date => {
  return fromZonedTime(dateTime, "UTC");
};

export const getTimeAgo = (time: Date, strict: boolean = false): string => {
  if (strict)
    return formatDistanceStrict(time, new Date(), {
      addSuffix: true,
    });
  return formatDistance(time, new Date(), {
    addSuffix: true,
  });
};

// Returns string 'X days ago / In X days'
export const getDaysAgo = (time: Date): string => {
  return formatDistanceStrict(time, new Date(), {
    unit: "day",
  });
};

export const getDaysNumbers = (time: Date): number => {
  return differenceInDays(time, new Date());
};

export const beforePresent = (time: Date) => time < new Date();
